@import "@Static/css/common";
$pad: 24px;
.root {
    display: flex;
    flex-direction: column;
    max-height: 98vh;
    max-width: 500px;
    overflow: hidden;
    border-radius: 8px;
}

.editingDisabled {
    cursor: not-allowed !important;
}

.detailsMessage {
    background: #f1f5ff;
    border-radius: 8px;
    font-size: 14px;
    padding: 14px;
}

.paymentStatusHeader {
    color: rgba(39, 37, 34, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.paymentStatusText {
    color: rgba(39, 37, 34, 0.5);
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
}

.header {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-align: center;
    padding: $pad $pad;
}

.title {
    margin: auto;
    font-size: 24px;
    font-weight: 600;
    padding: 0 30px;
    word-break: break-word;
}
.subtitle {
    text-align: center;
    margin: -$pad 0 $pad / 4;
    font-size: 18px;
    color: #666;
}
.close {
    position: absolute;
    right: #{$pad - 10px};
    line-height: 24px;
    font-size: 44px;
    font-weight: 400;
    font-family: "Montserrat", monospace;
    opacity: 0.8;
    transition: 0.1s ease-in all;
    cursor: pointer;
    border-radius: 10000px;
    padding: 10px 8.3px;
    &:hover {
        opacity: 0.95;
        background-color: #eee7;
    }
}

.back {
    position: absolute;
    left: #{$pad - 10px};
    line-height: 18px;
    font-size: 28px;
    opacity: 0.8;
    transition: 0.1s ease-in all;
    cursor: pointer;
    border-radius: 10000px;
    padding: 8px;
    &:hover {
        opacity: 0.95;
        background-color: #ddd;
    }
}

.formContainer {
    padding: $pad/2 $pad 0;
    max-height: 80vh;
    overflow: auto;
    // Themed scrollbar to make it more visible to the user that the form scrolls down
    @extend .themedScrollbar;
}

.formField {
    margin: 0 4px;
}
.formTitle {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 5px;
    color: #454545;
    .mandatory {
        color: #f15152;
    }
    word-break: break-all;
}
.fieldContainer {
    display: flex;
}

.dropdownSelector {
    width: 100%;
    color: #333;
    font-size: 18px;
    div[class$="control"] {
        border-width: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-color: #dfe1e5;
    }
}

.formInput {
    color: #333;
    max-width: 100%;
    width: min(800px, 100%);
    border-width: 2px;
    border-color: #dfe1e5;
    padding: 8px 10px;
    border-radius: 4px;
    transition: 0.1s ease-out all;
    &:hover {
        border-color: #ccc;
    }
    &.fullWidth {
        width: 100%;
    }
    &.phoneField {
        border-radius: 0 4px 4px 0;
        border-width: 2px;
    }
    &.discountField {
        border-radius: 4px 0 0 4px;
        border-right-width: 0;
        &:hover:not(.formInputError) {
            @include colorCombinationXYZ(
                (
                    "boc": (
                        "key": 1,
                    ),
                ),
                "schema3"
            );
        }
    }
}
select.formInput {
    padding: 11px 10px;
    option:disabled {
        opacity: 0.3;
    }
}
.formInputError {
    &,
    &:hover {
        border-color: red;
    }
}
.formInputSuccess {
    &,
    &:hover {
        border-color: green;
    }
}
.fieldError {
    color: red;
    font-size: 13px;
    max-width: fit-content;
}
.fieldSuccess {
    color: green;
}
.flexCol {
    flex-direction: column;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.tNc {
    align-items: flex-start;
    font-size: 16px;
    input[type="checkbox"] {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-top: 5px;
        border-color: #aaa;
        border-radius: 4px;
    }
}

.otherTextContainer {
    &:not(.forMultiSelect) {
        margin: -2px 0 0px;
        z-index: 11;
    }
    &.forMultiSelect {
        margin: 4px 0 0;
    }
    .otherTextField {
        border-radius: 0 0 4px 4px;
    }
}

.tncLabel {
    color: #333;
    font-size: 16px;
}
.tNcLink {
    font-size: 15px;
    letter-spacing: unset;
    &,
    &:hover {
        color: #004ba8;
    }
}

.ctaButtonContainer {
    padding: 5px $pad;
    box-shadow: #3333 0 -6px 6px;
}

.ctaRow {
    opacity: 0.97;
}

.submitButton {
    font-size: inherit;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    letter-spacing: 1.25px;
    margin: 12px $pad 20px;
    transition: 0.2s ease-in all;
    cursor: pointer;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
                "color": (
                    255,
                    91,
                    0,
                ),
            ),
        ),
        "schema1"
    );
    box-shadow: 4px 4px 6px 0.5px #3335;
    &:hover:not(:disabled) {
        opacity: 0.9;
    }
    &:disabled {
        opacity: 0.6;
        filter: grayscale(0.5);
    }
    .ctaLoader {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                ),
            ),
            "schema3"
        );
        color: white;
        $size: 4px;
        height: $size;
        width: $size;
        font-size: 4px;
        margin: 0px 20px;
        &:after {
            @include colorCombinationXYZ(
                (
                    "bgc": (
                        "key": 1,
                    ),
                ),
                "schema3"
            );
        }
    }
}

.discountSubmit {
    margin: 0;
    padding: 14px 18px;
    background-color: transparent;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
            ),
        ),
        "schema3"
    );
    background-color: #ffffff;
    border: 2px solid;
    @include colorCombinationXYZ(
        (
            "boc": (
                "key": 1,
            ),
        ),
        "schema3"
    );
    font-size: 14px;
    font-weight: 600;
    height: min-content;
    min-width: 90px;
    white-space: pre;
    letter-spacing: 0.4px;
    border-radius: 0 8px 8px 0;
    &:disabled {
        opacity: 0.75;
        filter: grayscale(0.6);
        cursor: not-allowed;
    }
}
.callIcon {
    padding-left: 0.8rem;
    width: 2rem;
}

@media only screen and (max-width: 768px) {
    $pad: 12px;
    .root {
        max-width: 95vw;
    }
    .title {
        font-size: 17px;
    }
    .close {
        right: #{$pad - 10px};
    }
    .back {
        left: #{$pad - 10px};
    }
    .header {
        padding: $pad $pad;
        border-bottom: 1.5px solid #efefef;
    }
    .formContainer {
        padding: $pad $pad $pad/4;
        // Subtracting the height of the other two boxes to ensure the modal always stays at 80vh
        max-height: calc(75vh - 128px);
    }
    .formTitle {
        font-size: 16px;
    }
    .formInput {
        font-size: 16px;
        padding: 10px 10px;
    }
    .dropdownSelector {
        font-size: 16px;
        div[class$="control"] {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
    .ctaButtonContainer {
        border-top: 1.5px solid #efefef;
    }
    .submitButton {
        margin: 12px $pad 16px;
        letter-spacing: 1px;
    }
    .showOnlyOnDesktop {
        display: none;
    }
    .uploadLabel {
        &.uploaded {
            padding: 12px 6px 4px;
        }
    }
}

.telegramIframe {
    border: 0;
    margin: 10px;
}
